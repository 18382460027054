import React from 'react';
import Xmas from './Xmas.jsx';
import Closed from './Closed.jsx';
import SomeTimes from '../../utils/SomeTimes.jsx';

export const Switcher = () => <>
  {/* <SomeTimes from="2024-11-11 09:00:00" to="2024-12-20 13:59:59"> */}
  <SomeTimes from="2024-10-11 09:00:00" to="2024-12-20 13:59:59">
    <Xmas/>
  </SomeTimes>
  <SomeTimes from="2024-12-20 14:00:00" to="2025-01-06 09:00:00">
    <Closed/>
  </SomeTimes>
</>


export default Switcher